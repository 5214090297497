import React, { useMemo } from 'react'

import {
  DateField,
  AssetReference,
  MultiAssetReference,
  WorkflowReference,
  MultiWorkflowReference,
  LongText,
  ShortText,
  EmailInput,
  PDFDocument,
  PDFScan,
  IpAddress,
  LabelField,
  SingleSelectionList,
  MultiSelectionList,
  FutureDateField,
  PastDateField,
  DateTimeField,
  NumberField,
  DecimalField,
  PhoneNumberWithCountryCode,
  WordDocument,
  ExcelDocument,
  OutlookDocument,
  DocumentRepositoryField,
  BusinessCardField,
  MultiTypeFileAttachmentField,
  ZipFileAttachmentField,
  DropboxDocumentField,
  ProductCartField,
  CompositLovField,
  AccountCreditDebitTransaction,
  Location,
  Address,
  OnlineList,
  CameraImage,
  GalleryImage,
  EventSchedule,
  GenralSignature,
  GeneralPicnature,
  CurrencyField,
  MACAddress,
  Reminder,
  SlotAvailability,
  QRCode,
  JSONField,
  FileReference,
  DynamicFields,
  MultiChoiceLov,
  Leave,
  ConveyanceField,
  Payment
} from '../components'

const FieldConfig = {
  1: DateField,
  2: FutureDateField,
  3: PastDateField,
  4: DateTimeField,
  5: NumberField,
  6: DecimalField,
  7: ShortText,
  17: Location,
  18: CurrencyField,
  19: ShortText,
  20: LongText,
  21: LabelField,
  22: EmailInput,
  23: PhoneNumberWithCountryCode,
  24: GalleryImage,
  25: CameraImage,
  27: GenralSignature,
  28: GeneralPicnature,
  32: PDFDocument,
  33: SingleSelectionList,
  34: MultiSelectionList,
  35: QRCode,
  // 50: FileReference,
  51: PDFScan,
  52: ExcelDocument,
  53: IpAddress,
  54: MACAddress,
  55: WordDocument,
  56: OutlookDocument,
  57: WorkflowReference,
  59: AssetReference,
  // 60: SlotAvailability,
  62: AccountCreditDebitTransaction,
  64: Address,
  65: BusinessCardField,
  66: DocumentRepositoryField,
  67: Reminder,
  68: MultiWorkflowReference,
  69: MultiAssetReference,
  70: OnlineList,
  71: ProductCartField,
  72: MultiTypeFileAttachmentField,
  73: ZipFileAttachmentField,
  74: CompositLovField,
  75: JSONField,
  76: DropboxDocumentField,
  77: EventSchedule,
  79: MultiChoiceLov,
  80: DynamicFields,
  81: Leave,
  82: ConveyanceField,
  83: Payment
}

export const FormInputField = ({ field, ...rest }) => {
  if (!!FieldConfig[field.data_type_id]) {
    return React.createElement(FieldConfig[field.data_type_id], {
      key: field.field_id,
      field: field,
      ...rest,
    })
  }

  return null
}

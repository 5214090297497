import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	FormLabel,
	HStack,
	Icon,
	IconButton,
	VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState, useRef } from 'react'
import { FiPlus } from 'react-icons/fi'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { BsInfoCircle } from 'react-icons/bs'
import { useSmartFormContext } from '../../../context/smart-form.context'
import { smartFormConst } from '../../../utils/action.constant'
import { toSnakeCase } from '../../../utils/common.util'
import { loadFinalValue, arithmaticCalcu } from '../../../utils/form.util'
import { useAuthContext } from '../../../context/auth.context'
import { FormInputField } from './field.config'
// import {
// 	dataLayerTagManager,
// 	GA_CATEGORY_FORM_MANAGEMENT,
// 	sectionDetailedTrack,
// } from '../../../containers/repository/repo.utils'
import { useLanguageContext } from '../../../context/locale.context'
import { debounce } from 'lodash'
import { useUserIdValidation } from '../../../hooks/form.hooks'

const FieldItem = ({
	fieldList,
	field = {},
	current = {},
	fieldIndex,
	onPrevious,
	onContinue,
	showFieldAction,
	accountError,
	activity,
	onFormAddFly,
	workforceForms,
	isOnFly,
	isBulkEdit = false,
	onSaveDraft = null,
	activity_type_flag_enable_draft = 0,
	skipFieldList = [],
	refillView = false,
	setEnableSubmit,
	isSmartForm = false,
	setCheckValidate = () => {},
	scrollToBottom = () => {},
	onShowDescription = () => {},
}) => {
	const [isFieldValidate, setIsFieldValidate] = useState(false)
	const [selectedValue, setSelectedValue] = useState(null)
	const [error, setError] = useState(null)
	const [onFlyAccess, setOnFlyAccess] = useState(false)
	const [onFlyForm, setOnFlyForm] = useState({})
	const [fileUpload, setFileUplaod] = useState(false)
	const [skip, setSkip] = useState(false)
	const [isDelete, setDelete] = useState(false)
	const [isDesignation, setDesignation] = useState(false)
	const [disableButton, setDisableButton] = useState(false)
	const [nextId, setNextId] = useState('')
	const [arithmatiVal, setarithmatiVal] = useState(null)
	const { field_mandatory_enabled: isRequired } = field
	const {
		state: { fields },
		dispatch,
	} = useSmartFormContext()

	const {
		state: {
			authData: { organization_flag_enable_grid },
		},
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()

	const { mutate } = useUserIdValidation()

	const validateService = value => {
		if (!!value)
			mutate(
				{
					activity_inline_data: JSON.stringify([
						loadFinalValue(field, value)?.final,
					]),
					field_id: field.field_id,
				},
				{
					onSuccess: async data => {
						if (data.length) {
							if (data[0]?.message !== 'User id available') {
								onValidate(false)
								onError(data[0]?.message)
							} else {
								onValidate(true)
								onError(false)
							}
						}
					},
					onError: async err => console.log(err),
				}
			)
	}

	const setServiceCallDebounced = useRef(debounce(validateService, 1000))

	useEffect(() => {
		if ([57, 68].includes(field.data_type_id)) {
			let fieldInfo =
				!!field.field_inline_data &&
				!!field.field_inline_data.workflow_reference_restriction &&
				!!field.field_inline_data.workflow_reference_restriction
					.activity_type_id
					? field.field_inline_data.workflow_reference_restriction
							.activity_type_id
					: null
			if (
				workforceForms &&
				workforceForms.list &&
				workforceForms.list.length > 0 &&
				!!fieldInfo
			) {
				let selectedActivity = workforceForms.list.find(
					item => item.form_workflow_activity_type_id === fieldInfo
				)
				if (!!selectedActivity) {
					setOnFlyAccess(true)
					setOnFlyForm(selectedActivity)
				}
			}
		}
	}, [field, workforceForms])

	useEffect(() => {
		if (!!field.data_type_id && [57, 68].includes(field.data_type_id)) {
			if (
				!!field.field_inline_data.disable_plus_button &&
				field.field_inline_data.disable_plus_button === 1
			) {
				setDisableButton(true)
			}
		}
	}, [field])

	let fValue =
		!!fields[field.form_id] &&
		!!fields[field.form_id][field.field_id] &&
		fields[field.form_id][field.field_id].field_value

	useEffect(() => {
		if (fValue) {
			if (
				!!field.field_inline_data &&
				field.field_inline_data.flag_validation_enabled === 1 &&
				!!field.field_inline_data.ethernet_url
			) {
				onValidate(false)
				setServiceCallDebounced.current(fValue)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fValue, field.field_inline_data])

	if (!field) {
		return
	}

	const onValidateAndContinue = () => {
		setDesignation(true)
		setError(false)
		// Do the field value validation here.
		// TODO: next fieldId will be changes in case of select
		if (selectedValue) {
			onContinue(selectedValue.comboNextFieldId, true)
		} else {
			onContinue(field.next_field_id, true)
		}
	}

	const onError = err => {
		setError(err)
	}

	const onSkip = () => {
		setSkip(true)
		setError(false)
		// TODO: clear the data on skip
		onContinue(field.next_field_id, true)
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: {
				final: {
					form_id: field.form_id,
					field_id: field.field_id,
					field_reference_id: field.field_reference_id,
					field_name: field.field_name,
					field_data_type_id: 0,
					field_data_type_category_id: 0,
					data_type_combo_id: '0',
					message_unique_id: 0,
					field_value: '',
				},
				field_value: '',
				is_skip: true,
			},
		})
	}

	if (field.data_type_id === 21) {
		return (
			<FormInputField
				field={field}
				isDisabled={field.field_id === current.field_id ? false : true}
				onSkip={onSkip}
			/>
		)
	}

	const onValidate = (valid, options = null, frm_input = false) => {
		setSelectedValue(options)
		setIsFieldValidate(valid)
		const err = field.field_mandatory_enabled === 1 ? !valid : false
		setError(err)
		setEnableSubmit(err)
		let sk = isSkipConfig(field)
		setCheckValidate({
			field_id: field.field_id,
			field_name: field.field_name,
			field_mandatory_enabled: field.field_mandatory_enabled,
			valid: field.field_mandatory_enabled === 1 ? (sk ? sk : valid) : true,
		})
		if (!!options && !!options.value && frm_input) {
			let s_field =
				field.data_type_id === 33
					? [
							...new Set(
								field.options.map(c => c.comboNextFieldId).filter(Boolean)
							),
					  ]
					: [0]
			if (
				!!options.comboNextFieldId &&
				s_field.length > 1 &&
				organization_flag_enable_grid === 1
			)
				onPrevious(fieldIndex)
		}
	}

	const disableCondition = () => {
		if (!!isBulkEdit && field.field_value_edit_enabled === 1) {
			return false
		} else {
			if (field.data_type_id === 74) {
				if (organization_flag_enable_grid === 1) {
					return false
				} else {
					return !(
						field.field_id === current.field_id &&
						(field.field_value_prefill_enabled !== 1 ||
							field.field_value_edit_enabled !== 1)
					)
				}
			} else {
				if (organization_flag_enable_grid === 1) {
					if (field.field_value_prefill_enabled === 1) {
						return field.field_inline_data
							?.field_flag_prefilled_data_enabled === 1
							? false
							: true
					} else {
						let sk = isSkipConfig(field)
						return sk
					}
				} else {
					if (field.field_value_prefill_enabled === 1) {
						return field.field_inline_data
							?.field_flag_prefilled_data_enabled === 1
							? false
							: true
					} else {
						return field.field_id !== current.field_id
					}
				}
			}
		}
	}

	const isSkipConfig = field => {
		if (field?.field_inline_data?.field_skip_condition) {
			let { data_type_combo_id, field_id } =
				field.field_inline_data.field_skip_condition
			let skipComboId =
				!!fields[field.form_id] && !!fields[field.form_id][field_id]
					? fields[field.form_id][field_id]?.final?.data_type_combo_id
					: null
			if (data_type_combo_id === skipComboId) {
				return field.field_id
			}
			return 0
		}
		return [21, 17].includes(field.data_type_id) ? 1 : 0
	}

	if (field.field_id === isSkipConfig(field)) {
		return <></>
	}

	let ref = React.createRef()

	let f_width = field.data_type_id === 4 ? '40%' : '30%'

	return (
		<HStack
			ref={ref}
			key={field.field_id}
			w='full'
			style={
				organization_flag_enable_grid === 1
					? {
							WebkitBoxAlign: 'initial',
							margin: '5px',
							display: '-webkit-box',
							flex: `1 0 ${f_width}`,
					  }
					: {}
			}
			className={`field-item-${toSnakeCase(field.field_name)}`}
		>
			<FormControl
				boxShadow={
					organization_flag_enable_grid === 1
						? '1px 1px 5px 0px rgba(0,0,0,0.2)'
						: 'md'
				}
				borderRadius='md'
				id={`field-item-${toSnakeCase(field.field_name)}`}
				p={2}
				mb={2}
				isRequired={!!isRequired}
			>
				<HStack alignItems='center' justifyContent='space-between' mb={2}>
					<VStack>
						<FormLabel
							className={`field-item-icon-button-${toSnakeCase(
								field.field_name
							)}`}
							fontWeight='400'
							fontSize='md'
							my={1}
						>
							{field.field_name}
						</FormLabel>
						{/* {field.field_description ? (
              <FormHelperText>{field.field_description}</FormHelperText>
            ) : null} */}
					</VStack>
					<HStack>
						{field &&
						(field.field_id === current.field_id ||
							organization_flag_enable_grid === 1) &&
						onFlyAccess &&
						!disableButton &&
						!isOnFly ? (
							<IconButton
								className={`field-item-icon-button-${toSnakeCase(
									field.field_name
								)}`}
								bg='white'
								aria-label='close'
								size='sm'
								borderRadius='md'
								boxShadow='md'
								_focus={{
									bg: 'secondary',
								}}
								_hover={{
									bg: 'secondary',
								}}
								icon={
									<Icon
										className={`field-item-plus-icon`}
										as={FiPlus}
										w={4}
										h={4}
									/>
								}
								onClick={() => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_FORM_MANAGEMENT,
									// 	action: 'Field Item',
									// 	label: 'Form On Fly',
									// })
									onFlyForm['data_type_id'] = field.data_type_id
									onFlyForm['field_id'] = field.field_id
									onFormAddFly(onFlyForm)
								}}
							/>
						) : null}
						{(field &&
							!isBulkEdit &&
							current &&
							field.field_id !== current.field_id) ||
						showFieldAction ? (
							organization_flag_enable_grid !== 1 && !isSkipConfig(field) ? (
								<HStack>
									<IconButton
										className={`field-item-close-button-${toSnakeCase(
											field.field_name
										)}`}
										bg='white'
										aria-label='close'
										size='sm'
										borderRadius='md'
										boxShadow='md'
										_focus={{
											bg: 'secondary',
										}}
										_hover={{
											bg: 'secondary',
										}}
										icon={
											<Icon
												className={`field-item-pencil-icon`}
												as={HiOutlinePencilAlt}
												w={4}
												h={4}
											/>
										}
										onClick={() => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_FORM_MANAGEMENT,
											// 	action: 'Field Item',
											// 	label: 'Go to previous',
											// })
											onPrevious(fieldIndex)
										}}
									/>
									{!!field.field_description && (
										<IconButton
											className={`field-item-close-button-${toSnakeCase(
												field.field_name
											)}`}
											bg='white'
											aria-label='close'
											size='sm'
											borderRadius='md'
											boxShadow='md'
											_focus={{
												bg: 'secondary',
											}}
											_hover={{
												bg: 'secondary',
											}}
											icon={
												<Icon
													className={`field-item-info-icon`}
													as={BsInfoCircle}
													w={4}
													h={4}
												/>
											}
											onClick={() => {
												// sectionDetailedTrack({
												// 	category: GA_CATEGORY_FORM_MANAGEMENT,
												// 	action: 'Field Item',
												// 	label: 'Show Description',
												// })
												onShowDescription(field)
											}}
										/>
									)}
								</HStack>
							) : (
								!!field.field_description && (
									<IconButton
										className={`field-item-close-button-${toSnakeCase(
											field.field_name
										)}`}
										bg='white'
										aria-label='close'
										size='sm'
										borderRadius='md'
										boxShadow='md'
										_focus={{
											bg: 'secondary',
										}}
										_hover={{
											bg: 'secondary',
										}}
										icon={
											<Icon
												className={`field-item-info-icon`}
												as={BsInfoCircle}
												w={4}
												h={4}
											/>
										}
										onClick={() => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_FORM_MANAGEMENT,
											// 	action: 'Field Item',
											// 	label: 'Show Description',
											// })
											onShowDescription(field)
										}}
									/>
								)
							)
						) : (
							!!field.field_description && (
								<IconButton
									className={`field-item-close-button-${toSnakeCase(
										field.field_name
									)}`}
									bg='white'
									aria-label='close'
									size='sm'
									borderRadius='md'
									boxShadow='md'
									_focus={{
										bg: 'secondary',
									}}
									_hover={{
										bg: 'secondary',
									}}
									icon={
										<Icon
											className={`field-item-info-icon`}
											as={BsInfoCircle}
											w={4}
											h={4}
										/>
									}
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_FORM_MANAGEMENT,
										// 	action: 'Field Item',
										// 	label: 'Show Description',
										// })
										onShowDescription(field)
									}}
								/>
							)
						)}
					</HStack>
				</HStack>
				{
					<FormInputField
						activity={activity}
						isRequired={isRequired}
						field={field}
						fieldIndex={fieldIndex}
						onPrevious={onPrevious}
						skip={skip}
						fieldList={fieldList}
						isDisabled={disableCondition()}
						//isDisabled={false} //false for grid view forms
						onValidate={onValidate}
						onError={onError}
						onDelete={params => setDelete(params)}
						focus={field.field_id === current.field_id}
						isFileUpload={params => {
							setDelete(false)
							setFileUplaod(params)
						}}
						isDesignation={isDesignation}
						scrollToBottom={e => scrollToBottom(e, ref)}
					/>
				}

				<HStack
					justifyContent='space-between'
					my={2}
					alignItems='center'
					pr={4}
					pl={2}
					w='full'
				>
					{!!error || !!accountError ? (
						<FormHelperText color='red.300' fontWeight='400' fontSize='12px'>
							{error || accountError || 'Error'}
						</FormHelperText>
					) : (
						<Box flex={1}></Box>
					)}
					{field &&
					current &&
					!isBulkEdit &&
					field.field_id === current.field_id &&
					!fileUpload &&
					!showFieldAction ? (
						<Box justifySelf='flex-end'>
							{!isRequired ? (
								<Button
									className={`field-item-skip-button-${toSnakeCase(
										field.field_name
									)}`}
									variant='link'
									colorScheme={localStorage.getItem('color')}
									size='sm'
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_FORM_MANAGEMENT,
										// 	action: 'Field Item',
										// 	label: 'Skip Item',
										// })
										onSkip()
									}}
								>
									{locale['Skip']}
								</Button>
							) : null}
							<Button
								className={`field-item-continue-button-${toSnakeCase(
									field.field_name
								)}`}
								variant='link'
								colorScheme={localStorage.getItem('color')}
								size='sm'
								ml={2}
								mr={2}
								isDisabled={!isFieldValidate || (isRequired && isDelete)}
								onClick={() => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_FORM_MANAGEMENT,
									// 	action: 'Field Item',
									// 	label: 'Validating',
									// })
									onValidateAndContinue()
								}}
							>
								{locale['Continue']}
							</Button>
							{activity_type_flag_enable_draft === 1 && !refillView && (
								<Button
									className={`field-item-draft-button-${toSnakeCase(
										field.field_name
									)}`}
									variant='link'
									colorScheme='red'
									size='sm'
									isDisabled={
										organization_flag_enable_grid !== 1 &&
										(!isFieldValidate || (isRequired && isDelete))
									}
									onClick={e => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_FORM_MANAGEMENT,
										// 	action: 'Field Item',
										// 	label: 'Save As Draft',
										// })
										// dataLayerTagManager('button_click', {
										// 	viewName: 'Form',
										// 	buttonName: 'Save as Draft',
										// })
										onSaveDraft(e)
									}}
								>
									{locale['Save Draft']}
								</Button>
							)}
						</Box>
					) : null}
				</HStack>
			</FormControl>
		</HStack>
	)
}

export default FieldItem

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Input, VStack } from '@chakra-ui/react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import {
  getMessageUniqueId,
  toSnakeCase,
} from '../../../../../utils/common.util'
import { smartFormConst } from '../../../../../utils/action.constant'
const Address = ({ field, isDisabled = true, isRequired, onValidate }) => {
  const {
    state: { fields: formFields },
    dispatch,
  } = useSmartFormContext()
  const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

  const {
    field_id,
    form_id,
    field_reference_id,
    field_name,
    data_type_id,
    data_type_category_id,
  } = field

  // const [officeUnitNumber, setOfficeUnitNumber] = useState('');
  // const [floorNumber, setFloorNumber] = useState('');
  // const [buildingName, setBuildingName] = useState('');
  // const [streetNumber, setStreetNumber] = useState('');
  // const [streetName, setStreetName] = useState('');
  // const [areaName, setAreaName] = useState('');
  // const [addressCity, setAddressCity] = useState('');
  // const [pinCode, setPinCode] = useState('');
  // const [contactState, setContactState] = useState('');

  const [addressVal, setAddressVal] = useState({
    office_unit_number: '',
    floor_number: '',
    building_name: '',
    street_number: '',
    street_name: '',
    area_name: '',
    city: '',
    pincode: '',
    state: '',
  })

  useEffect(() => {
    if (
      fields[field.field_id] &&
      fields[field.field_id].field_value &&
      !!fields[field.field_id].field_value.isFromEdit
    ) {
      let fieldValue = fields[field.field_id].field_value.value
      fieldValue =
        typeof fieldValue === 'string' && !!fieldValue
          ? JSON.parse(fieldValue)
          : fieldValue
      setAddressVal({
        office_unit_number: fieldValue?.office_unit_number || '',
        floor_number: fieldValue?.floor_number || '',
        building_name: fieldValue?.building_name || '',
        street_number: fieldValue?.street_number || '',
        street_name: fieldValue?.street_name || '',
        area_name: fieldValue?.area_name || '',
        city: fieldValue?.city || '',
        pincode: fieldValue?.pincode || '',
        state: fieldValue?.state || '',
      })
      onValidate(true)
    }
  }, [fields[field.field_id]])

  // const onStateChange = value => {
  //   setContactState(value);
  //   if (
  //     !!officeUnitNumber &&
  //     !!buildingName &&
  //     !!floorNumber &&
  //     !!pinCode &&
  //     !!addressCity &&
  //     !!value &&
  //     (!!streetNumber || !!streetName)
  //   ) {
  //     onValidate(true);
  //   } else {
  //     onValidate(false);
  //   }

  //   let contact_value = {
  //     office_unit_number: officeUnitNumber,
  //     floor_number: floorNumber,
  //     building_name: buildingName,
  //     street_number: streetNumber,
  //     street_name: streetName,
  //     area_name: areaName,
  //     city: addressCity,
  //     pincode: pinCode,
  //     state: value,
  //   };
  //   dispatch({
  //     type: smartFormConst.SET_FIELD_VALUE,
  //     fieldId: field.field_id,
  //     formId: field.form_id,
  //     value: {
  //       final: {
  //         form_id,
  //         field_id,
  //         field_reference_id,
  //         field_name,
  //         field_data_type_id: data_type_id,
  //         field_data_type_category_id: data_type_category_id,
  //         field_value: contact_value,
  //         message_unique_id: getMessageUniqueId(),
  //       },
  //       field_value: contact_value,
  //     },
  //   });

  // };

  const handleChage = event => {
    const { name, value } = event.target
    setAddressVal(prev => ({
      ...prev,
      [name]: value,
    }))
    let finalVal = addressVal
    finalVal[name] = value
    if (
      Object.keys(finalVal).filter(
        val =>
          [
            'office_unit_number',
            'floor_number',
            'building_name',
            'street_name',
            'city',
            'pincode',
            'state',
          ].includes(val) && !finalVal[val]
      ).length > 0
    ) {
      onValidate(false)
    } else {
      onValidate(true)
    }
    dispatch({
      type: smartFormConst.SET_FIELD_VALUE,
      fieldId: field.field_id,
      formId: field.form_id,
      value: {
        final: {
          form_id,
          field_id,
          field_reference_id,
          field_name,
          field_data_type_id: data_type_id,
          field_data_type_category_id: data_type_category_id,
          field_value: finalVal,
          message_unique_id: getMessageUniqueId(),
        },
        field_value: finalVal,
      },
    })
  }
  return (
    <VStack
      spacing={1}
      className={`address-input-field-${toSnakeCase(field_name)}`}
    >
      <Input
        type="text"
        // required={isRequired}
        //autoFocus={true}
        isDisabled={isDisabled}
        id={'officeUnitNumber'}
        borderRadius="md"
        size="sm"
        placeholder="Office unit number*"
        name="office_unit_number"
        value={addressVal.office_unit_number}
        onChange={handleChage}
      />
      <Input
        type="text"
        // required={isRequired}
        isDisabled={isDisabled}
        borderRadius="md"
        size="sm"
        id={'floorNumber'}
        placeholder="Floor number*"
        name="floor_number"
        value={addressVal.floor_number}
        onChange={handleChage}
      />
      <Input
        type="text"
        // required={isRequired}
        isDisabled={isDisabled}
        borderRadius="md"
        size="sm"
        id={'buildingName'}
        placeholder="Building name*"
        name="building_name"
        value={addressVal.building_name}
        onChange={handleChage}
      />
      <Input
        type="text"
        // required={isRequired}
        isDisabled={isDisabled}
        borderRadius="md"
        id={'streetNumber'}
        size="sm"
        placeholder="Street Number"
        name="street_number"
        value={addressVal.street_number}
        onChange={handleChage}
      />

      <Input
        type="text"
        // required={isRequired}
        isDisabled={isDisabled}
        borderRadius="md"
        size="sm"
        id={'streetName'}
        placeholder="Street name*"
        name="street_name"
        value={addressVal.street_name}
        onChange={handleChage}
      />
      <Input
        type="text"
        // required={isRequired}
        isDisabled={isDisabled}
        borderRadius="md"
        size="sm"
        id={'areaName'}
        placeholder="Area name"
        name="area_name"
        value={addressVal.area_name}
        onChange={handleChage}
      />
      <Input
        type="text"
        // required={isRequired}
        isDisabled={isDisabled}
        borderRadius="md"
        size="sm"
        id={'addressCity'}
        placeholder="City*"
        name="city"
        value={addressVal.city}
        onChange={handleChage}
      />
      <Input
        type="text"
        // required={isRequired}
        isDisabled={isDisabled}
        borderRadius="md"
        size="sm"
        // id={'pinCode'}
        placeholder="Pin code*"
        name="pincode"
        value={addressVal.pincode}
        onChange={handleChage}
      />
      <Input
        type="text"
        // required={isRequired}
        isDisabled={isDisabled || !addressVal.pincode || !addressVal.city}
        borderRadius="md"
        size="sm"
        id={'contactState'}
        placeholder="State name*"
        name="state"
        value={addressVal.state}
        onChange={handleChage}
      />
    </VStack>
  )
}

Address.propTypes = {
  field: PropTypes.object.isRequired,
}

export default Address

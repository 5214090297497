/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Stack, FormLabel, VStack, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { toSnakeCase } from '../../../../../utils/common.util'
import { loadFinalValue } from '../../../../../utils/form.util'
import {
	useLoadDynamicFieldsList,
	useFetchLovListForRefrence,
	useLoadOnlineDependentsList,
} from '../../../../../hooks/form.hooks'
/**
 * dataTypeID 80
 * dataTypeName  Dynamic Fields
 */

const DynamicFields = ({
	field,
	fieldList,
	isDisabled = true,
	isRequired,
	onValidate,
	onError,
	activity,
	skip,
	focus,
}) => {
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

	const ref = useRef().current
	const [dynamicFields, setDynamicFields] = useState([])
	const [errors, setErrors] = useState([])
	const { mutate } = useLoadDynamicFieldsList()
	const { mutate: lovListMutate } = useFetchLovListForRefrence()
	const { mutate: dependentListMutate } = useLoadOnlineDependentsList()
	const [isLoaded, setIsLoaded] = useState(false)

	const handleValidation = array => {
		let err = []
		let initArray = [...array]
		initArray.forEach((el, index) => {
			let param_validation_data = !!initArray[index]?.['param_validation_data']
				? JSON.parse(initArray[index]['param_validation_data'])
				: {}
			let param_flag_validation_enable = !!initArray[index]?.[
				'param_flag_validation_enable'
			]
				? initArray[index]?.['param_flag_validation_enable']
				: 0
			if (
				param_validation_data?.validation_type_id === 1 &&
				param_flag_validation_enable
			) {
				const {
					validation_data: { validation_symbol, validation_number },
				} = param_validation_data

				if (validation_symbol) {
					let checkNum =
						Number(initArray[index]['param_value']) +
						validation_symbol +
						validation_number
					if (!eval(checkNum)) {
						err[index] = {
							error:
								'Enter value ' + validation_symbol + ' ' + validation_number,
						}
					} else {
						delete err[index]
					}
				}
			} else if (
				param_validation_data?.validation_type_id === 2 &&
				param_flag_validation_enable
			) {
				const {
					validation_data: { validation_allowed_values = [] },
				} = param_validation_data
				if (
					!validation_allowed_values.includes(initArray[index]['param_value'])
				) {
					err[index] = {
						error:
							'Valid options are ' +
							validation_allowed_values.map(n => n).join(', '),
					}
				} else {
					delete err[index]
				}
			} else if (
				param_validation_data?.validation_type_id === 3 &&
				param_flag_validation_enable
			) {
				const { validation_data } = param_validation_data
				const createRegExp = (str, opts) =>
					new RegExp(str.raw[0].replace(/\s/gm, ''), opts || '')
				const regEx = createRegExp`
      ^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|
      (\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|
      (([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$`
				if (!regEx.test(initArray[index]['param_value'])) {
					err[index] = {
						error: 'Invalid Email',
					}
				} else {
					delete err[index]
				}
			}
		})
		setErrors(err)
		onValidate(err.length === 0)
	}

	const handleChange = e => {
		let initArray = [...dynamicFields]
		let index = initArray.findIndex(
			d => `${d.param_id}-${d.param_name}` === e.target.name
		)
		if (index > -1) {
			initArray[index]['param_value'] = e.target.value
			let validate = initArray.every(s => !!s.param_value)
			onValidate(validate)
			setDynamicFields(initArray)
			onChange(initArray)
			let param_validation_data = !!initArray[index]?.['param_validation_data']
			if (param_validation_data) handleValidation(initArray)
		}
	}

	const buildLovDependentListParams = (flag, entity_id, srchStr, type_id) => {
		let txtToSrch = ''
		if (srchStr && srchStr !== '') {
			txtToSrch = srchStr
		}
		return {
			workforce_id: 0,
			type_id: type_id,
			entity_id: entity_id,
			search_string: txtToSrch,
			flag: flag,
		}
	}

	const onChange = value => {
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: loadFinalValue(field, value),
		})
	}

	useEffect(() => {
		let { lov_restriction } = field.field_inline_data
		let { dependent_field_id, flag_dependent } = !!lov_restriction
			? lov_restriction
			: {}
		let entity_id_1 = 0
		let entity_id_2 = []
		async function getId() {
			if (!!flag_dependent && flag_dependent === 1 && !!dependent_field_id) {
				let entity_ids =
					!!fields[dependent_field_id] &&
					!!fields[dependent_field_id].field_value
						? // !fields[dependent_field_id].field_value.isFromEdit
						  Array.isArray(fields[dependent_field_id].field_value)
							? fields[dependent_field_id].field_value
							: [fields[dependent_field_id].field_value]
						: []

				// let dep = fieldList.filter(f => f.field_id === dependent_field_id)
				// let {
				// 	field_inline_data: { lov_restriction },
				// 	field_id,
				// 	data_type_id,
				// } = !!dep[0] ? dep[0] : { field_inline_data: { lov_restriction: {} } }

				// if (
				// 	entity_ids.length === 0 &&
				// 	flag_dependent === 1 &&
				// 	!!fields[dependent_field_id] &&
				// 	!!fields[dependent_field_id].field_value &&
				// 	fields[dependent_field_id].field_value.isFromEdit
				// ) {
				// 	let entity_ids_edit =
				// 		!!fields[dependent_field_id] &&
				// 		!!fields[dependent_field_id].field_value
				// 			? data_type_id === 79
				// 				? !!fields[dependent_field_id].field_value.finalArray
				// 					? fields[dependent_field_id].field_value.finalArray
				// 					: []
				// 				: [fields[dependent_field_id].field_value]
				// 			: []

				// 	if (
				// 		lov_restriction?.flag_dependent !== 0 &&
				// 		!!lov_restriction.dependent_field_id
				// 	) {
				// 		let dep_p = fieldList.filter(
				// 			f => f.field_id === lov_restriction.dependent_field_id
				// 		)
				// 		let {
				// 			field_inline_data: { lov_restriction: lov_restriction_p },
				// 			field_id: field_id_p,
				// 		} = !!dep_p[0] ? dep_p[0] : {}
				// 		let resp1 = await new Promise((resolve, reject) => {
				// 			lovListMutate(
				// 				{
				// 					workforce_id: 0,
				// 					type_id: !!lov_restriction_p
				// 						? lov_restriction_p?.lov_type_id
				// 						: 0,
				// 					entity_id:
				// 						Number(lov_restriction_p?.lov_type_id) === 2018
				// 							? !!activity
				// 								? activity.activity_id
				// 								: 0
				// 							: 0,
				// 					search_string:
				// 						!!fields[field_id_p] &&
				// 						fields[field_id_p].field_value.value,
				// 					flag: Number(lov_restriction_p?.lov_type_id) === 2010 ? 2 : 1, //typeID 2010 is for VIL City Feasibilty LOV
				// 					sort_flag: 1,
				// 					workflow_activity_id: 0,
				// 					field_id: field?.field_id,
				// 				},
				// 				{
				// 					onSuccess: async res => {
				// 						resolve(res.data.response)
				// 					},
				// 					onError: async err => {
				// 						reject([])
				// 					},
				// 				}
				// 			)
				// 		})
				// 		entity_id_1 = !!resp1 && !!resp1[0] ? resp1[0].details.lov_id : 0

				// 		let ser = entity_ids_edit

				// 		let resp2 = await new Promise((resolve, reject) => {
				// 			dependentListMutate(
				// 				{
				// 					...buildLovDependentListParams(
				// 						lov_restriction.lov_type_flag,
				// 						entity_id_1,
				// 						'',
				// 						lov_restriction.lov_type_id
				// 					),
				// 				},
				// 				{
				// 					onSuccess: async res => {
				// 						resolve(res.data.response)
				// 					},
				// 				}
				// 			)
				// 		})
				// 		let respD2 = resp2.filter(f =>
				// 			ser.map(s => s.label).includes(f.label)
				// 		)
				// 		entity_id_2 =
				// 			!!resp2 && !!respD2[0] ? respD2.map(d => d?.details?.lov_id) : []
				// 	} else if (
				// 		lov_restriction?.flag_dependent !== 0 &&
				// 		!!lov_restriction.dependent_field_ids
				// 	) {
				// 		let dependentFieldIds = !!lov_restriction.dependent_field_ids
				// 			? lov_restriction.dependent_field_ids
				// 			: []
				// 		let entIds = dependentFieldIds.map(i => {
				// 			let dep_value =
				// 				!!fields[i] && !!fields[i].final
				// 					? fields[i].final?.data_type_combo_id
				// 					: 0
				// 			return dep_value
				// 		})
				// 		entity_id_2 = entIds
				// 	}
				// 	let params = {
				// 		type_id: !!lov_restriction ? lov_restriction.lov_type_id : 0,
				// 		entity_ids: JSON.stringify(entity_id_2),
				// 		flag: 0,
				// 	}

				// 	mutate(params, {
				// 		onSuccess: async res => {
				// 			if (res?.length > 0) {
				// 				setDynamicFields(res)
				// 				// onChange(res)
				// 				// let validate = res.every(s => !!s.param_value)
				// 				// onValidate(validate)
				// 			} else {
				// 				setDynamicFields([])
				// 				onValidate(false)
				// 			}
				// 		},
				// 		onError: async err => console.log(err),
				// 	})
				// } else {
				let getIds = entity_ids
					.filter(f => !f?.finalArray)
					.map(i => {
						if (i?.data_type_combo_id) {
							return i.data_type_combo_id
						} else {
							return i?.details?.lov_id
						}
					})

				let getIds2 = entity_ids
					.filter(f => !!f?.finalArray)
					.reduce((a, s) => {
						return [...a, ...s?.finalArray.map(m => m?.details?.lov_id)]
					}, [])

				let params = {
					type_id: !!lov_restriction ? lov_restriction.lov_type_id : 0,
					entity_ids: !!getIds2?.length
						? JSON.stringify(getIds2)
						: JSON.stringify(getIds),
					flag: 0,
				}
				if (entity_ids?.length > 0) {
					mutate(params, {
						onSuccess: async res => {
							setIsLoaded(true)
							if (res?.length > 0) {
								setDynamicFields(res)
								handleValidation(res)
								let validate = res.every(s => !!s.param_value)
								onValidate(validate)
								if (
									!!fields[field.field_id] &&
									fields[field.field_id].field_value.isFromEdit
								) {
									let finalArray = fields[field.field_id].field_value.finalArray
									if (finalArray.length) {
										let validate = finalArray.every(s => !!s.param_value)
										onValidate(validate)
										onChange(finalArray)
									}
								} else {
									onChange(res)
								}
							} else {
								setDynamicFields([])
								onValidate(true) //Prakash sir asked to enable on 11/05/2023
								if (
									!!fields[field.field_id] &&
									fields[field.field_id].field_value.isFromEdit
								) {
									let finalArray = fields[field.field_id].field_value.finalArray
									if (finalArray.length) {
										onChange(finalArray)
									} else {
										onChange([])
									}
								} else {
									onChange([])
								}
							}
						},
						onError: async err => console.log(err),
					})
				}
				//}
			}
		}
		if (!isLoaded) {
			getId()
		}
	}, [fields])

	useEffect(() => {
		if (skip) {
			onChange([])
			onError('')
		}
	}, [skip])

	let results =
		!!fields[field.field_id] && !!fields[field.field_id].field_value
			? fields[field.field_id].field_value
			: []

	return dynamicFields.map((f, i) => {
		let val = Array.isArray(results)
			? results
					.filter(
						r =>
							r.param_id === f.param_id &&
							r.param_name === f.param_name &&
							r.entity_id === f.entity_id
					)
					.reduce((a, s) => s.param_value, f.param_value)
			: f.param_value
		return (
			<Stack key={i} mb={2}>
				<VStack alignItems={'flex-start'}>
					<FormLabel
						className={`field-item-icon-button-${toSnakeCase(f.param_name)}`}
						fontWeight='400'
						fontSize='md'
						my={1}
					>
						{`${f.param_name} (${f?.entity_name})`}
					</FormLabel>
					<Input
						ref={ref}
						className={`short-text-field-${toSnakeCase(f.param_name)}`}
						autoComplete='off'
						type='text'
						name={`${f.param_id}-${f.param_name}`}
						required={isRequired}
						key={f.param_name}
						isDisabled={isDisabled || !!f.param_flag_disable_edit}
						placeholder={`Enter ${f.param_name}`}
						borderRadius='md'
						size='sm'
						focusable={focus}
						value={val}
						onChange={handleChange}
					/>
					{errors.length && (
						<Text color={'red.500'}>
							{!!errors?.[i] ? errors?.[i]?.error : ''}
						</Text>
					)}
				</VStack>
			</Stack>
		)
	})
}

export default DynamicFields

/* eslint-disable no-control-regex */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Input } from '@chakra-ui/react'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { toSnakeCase } from '../../../../../utils/common.util'
import { loadFinalValue } from '../../../../../utils/form.util'
import panGSTFields from '../../../../../utils/pan_gst_validation.util'
/**
 * dataTypeID 19
 * dataTypeName  Short Text
 */

const VALIDATION_SCHEMA = {
	2: /^[a-zA-Z0-9 ]*$/, ///Only Alpha Numeric Chars
	3: /^[a-zA-Z0-9-._!"`'#%&,:\s;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]*$/, // Only Alpha Numeric With special Chars
	4: /^[\x00-\x7F]+$/, //Only ASCII Chars
	5: /^([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1})$/, //// PAN Valdiation
	6: /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[1-9aAbBe-jE-JzZ]{1}[0-9a-zA-Z]{1})$/, // GST validation
	7: /^[a-zA-Z0-9#%&*()+-.,;:' '\/]*$/, // Only Alpha Numeric With special Chars for account name creation
	8: /^[A-Za-z ]+$/, //Only Alphabets
	9: /^[a-zA-Z0-9\-\s\&]*$/, //OnlyAlphaNumericwithSpace,HyphenandAmpersand
	10: /^[a-zA-Z0-9\-\s\_]*$/, //Only AlphaNumeric with Hyphen and Underscore
	13: /^[a-zA-Z0-9\_]*$/, //Only AlphaNumeric with  Underscore
	14: /^[a-zA-Z0-9\-\s]*$/, //Only AlphaNumeric with Hyphen with space and hyphab
	15: /^[a-zA-Z0-9\-]*$/, //Only AlphaNumeric with Hyphen
}

const ERROR_MESSAGE = {
	2: 'Invalid input only alphanumeric with no special characters allowed',
	3: 'Invalid input only alphanumeric with special chars Allowed',
	4: 'Invalid input only ASCII chars allowed',
	5: 'Invalid PAN number',
	6: 'Invalid GST pan number',
	7: `Invalid input only alphanumeric with #%&*()\/+-.,;:' special chars Allowed`,
	8: 'Invalid input only alphabets allowed',
	9: 'Invalid input only alphanumeric with hyphen and ampersand Allowed',
	10: 'Invalid input only alphanumeric with hyphen and Underscore Allowed',
	13: 'Invalid input only alphanumeric with Underscore Allowed',
	14: 'Invalid input only alphanumeric with space and hyphen Allowed',
	15: 'Invalid input only alphanumeric with hyphen  Allowed',
}

const ShortText = ({
	field,
	isDisabled = true,
	isRequired,
	onValidate,
	onError,
	skip,
	focus,
	activity,
}) => {
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()

	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}
	const [prefill_enable_edit, set_prefill_enable_edit] = useState(null)
	const ref = useRef().current
	const doValidate = value => {
		if (
			!!field.field_inline_data &&
			field.field_inline_data.flag_input_setting >= 2
		) {
			if (
				!!VALIDATION_SCHEMA[field.field_inline_data.flag_input_setting] &&
				!!VALIDATION_SCHEMA[field.field_inline_data.flag_input_setting].test(
					value
				)
			) {
				// PAN Number Validation
				if (
					panGSTFields.panFields.some(
						f => f.field_id === Number(field.field_id)
					)
				) {
					if (isRequired || value !== '') {
						if (VALIDATION_SCHEMA[5].test(value.trim())) {
							onValidate(true)
							onError('')
						} else {
							onValidate(false)
							onError(ERROR_MESSAGE[5])
						}
					}
				} else if (
					panGSTFields.gstFields.some(
						f => f.field_id === Number(field.field_id)
					) &&
					(isRequired || value !== '')
				) {
					let inValidGst = []
					let panFieldValue
					const gstFieldInfo = panGSTFields.gstFields.find(gstField => {
						return gstField.form_id === field.form_id
					})

					if (!!gstFieldInfo) {
						panFieldValue = fields[gstFieldInfo.pan_field_id]?.field_value
					}

					value.split(',').map(gstvalue => {
						let gstTrim = gstvalue.trim()
						if (!VALIDATION_SCHEMA[6].test(gstTrim)) {
							inValidGst.push(gstTrim)
						} else if (panFieldValue && !gstTrim.includes(panFieldValue)) {
							inValidGst.push(gstTrim)
						}
					})
					if (inValidGst.length > 0) {
						onValidate(false)
						onError(`${inValidGst.join(',')} are invalid GST numbers`)
					} else {
						onValidate(true)
					}
				} else {
					onValidate(true)
				}
			} else {
				onValidate(false)
				onError(ERROR_MESSAGE[field.field_inline_data.flag_input_setting])
			}
		} else if (value.charAt(0).match(/\s/)) {
			onValidate(false)
			onError(`Field should not start with space`)
		} else {
			onValidate(true)
		}

		if (
			field?.field_inline_data?.text_max_length &&
			field?.field_inline_data?.text_max_length < value.length
		) {
			onValidate(false)
			onError(
				`Max char length should be ${field.field_inline_data.text_max_length} (${value.length}/${field.field_inline_data.text_max_length})`
			)
		}
	}

	const valueRef = useRef('')
	const onChange = (value, isfield = false) => {
		if (!!isRequired) {
			if (!!value) {
				doValidate(value)
			} else {
				onValidate(false)
			}
		} else {
			doValidate(value)
		}
		if (isfield) {
			valueRef.current = value // Update the valueRef with the latest value
		} else {
			dispatch({
				type: smartFormConst.SET_FIELD_VALUE,
				fieldId: field.field_id,
				formId: field.form_id,
				value: loadFinalValue(field, value), // Access the value from valueRef
			})
		}
	}

	useEffect(() => {
		if (valueRef.current) {
			dispatch({
				type: smartFormConst.SET_FIELD_VALUE,
				fieldId: field.field_id,
				formId: field.form_id,
				value: loadFinalValue(field, valueRef.current), // Access the value from valueRef
			})
		}
	}, [valueRef.current])

	const handleHashData = hd => {
		hd = hd.replace(/'/g, '').replace(/"/g, '').replace(/ /g, '')
		switch (hd) {
			case '#q':
				return moment(new Date()).format('MM') < 4
					? 1
					: moment(new Date()).format('MM') < 7
					? 2
					: moment(new Date()).format('MM') < 10
					? 3
					: 4
			case '#h':
				return moment(new Date()).format('MM') > 6 ? 2 : 1
			case '#mm':
				return `${moment(new Date()).format('MM')}`
			case '#qq':
				return moment(new Date()).format('MM') < 4
					? `01`
					: moment(new Date()).format('MM') < 7
					? `04`
					: moment(new Date()).format('MM') < 10
					? `07`
					: `10`
			case '#hh':
				return moment(new Date()).format('MM') > 6 ? `07` : `01`
			case '#mmm':
				return moment(new Date()).format('MMM')
			case '#qqq':
				return moment(new Date()).format('MM') < 4
					? `Jan`
					: moment(new Date()).format('MM') < 7
					? `Apr`
					: moment(new Date()).format('MM') < 10
					? `Jul`
					: `Oct`
			case '#hhh':
				return moment(new Date()).format('MM') > 6 ? `Jul` : `Jan`
			case '#yy':
				return moment(new Date()).format('YY')
			case '#yyyy':
				return moment(new Date()).format('YYYY')
			default:
				return ''
		}
	}

	const configurePrefillId = data => {
		let id_string = ''
		data?.split('+').forEach(element => {
			if (element.includes('#')) {
				id_string = id_string + handleHashData(element)
			} else {
				id_string = id_string + element
			}
		})
		id_string = id_string?.replace(/'/g, '').replace(/ /g, '')
		return id_string
	}

	useEffect(() => {
		if (field?.field_inline_data?.prefill_config) {
			const {
				field_inline_data: { prefill_config },
			} = field
			const { field_enable_edit, field_prefill_data } = !!prefill_config
				? prefill_config
				: {}
			let c_value = configurePrefillId(field_prefill_data)

			if (c_value && field_prefill_data) {
				set_prefill_enable_edit(field_enable_edit)
				onChange(c_value, true)
			}
		}
	}, [field?.field_inline_data?.prefill_config])

	useEffect(() => {
		if (!!fields[field.field_id] && fields[field.field_id]?.field_value) {
			onChange(fields[field.field_id].field_value)
		}
	}, [!!fields[field.field_id]])

	useEffect(() => {
		if (fields[field.field_id]?.field_value) {
			doValidate(fields[field.field_id]?.field_value)
		}
	}, [fields[field.field_id]?.field_value])

	// const activityData =
	// 	activity?.activity_inline_data && JSON.parse(activity?.activity_inline_data)
	// let submitedFieldValue = ''
	// activityData?.map(item => {
	// 	if (item?.field_id === field?.field_id) {
	// 		submitedFieldValue = item?.field_value
	// 	}
	// })
	// useEffect(() => {
	// 	if (submitedFieldValue) {
	// 		onChange(submitedFieldValue)
	// 	}
	// }, [submitedFieldValue])

	useEffect(() => {
		if (skip) {
			onChange('')
			onError('')
		}
	}, [skip])
	return (
		<Input
			ref={ref}
			className={`short-text-field-${toSnakeCase(field.field_name)}`}
			autoComplete='off'
			type='text'
			required={isRequired}
			key={field.field_name}
			isDisabled={isDisabled || prefill_enable_edit === 0}
			placeholder={field.field_place_holder || `Enter ${field.field_name}`}
			borderRadius='md'
			size='sm'
			focusable={focus}
			value={fields[field.field_id] ? fields[field.field_id].field_value : ''}
			onChange={e => onChange(e.target.value)}
		/>
	)
}

export default ShortText

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-control-regex */
import { Textarea } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { toSnakeCase } from '../../../../../utils/common.util'
import { loadFinalValue } from '../../../../../utils/form.util'

/**
 * dataTypeId : 20
 * dataTypeName : Long Text
 */

const VALIDATION_SCHEMA = {
	2: /^[a-zA-Z0-9 ]*$/, ///Only Alpha Numeric Chars
	3: /^[a-zA-Z0-9-._!"`'#%&,:\s;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]*$/, // Only Alpha Numeric With special Chars
	4: /^[\x00-\x7F]+$/, //Only ASCII Chars
	5: /^([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1})$/, //// PAN Valdiation
	6: /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})$/, // GST validation
	7: /^[a-zA-Z0-9#%&*()+-.,;:' '\/]*$/, // Only Alpha Numeric With special Chars for account name creation
	8: /^[A-Za-z ]+$/, //Only Alphabets
	9: /^[a-zA-Z0-9\-\s\&]*$/, //OnlyAlphaNumericwithSpace,HyphenandAmpersand
	10: /^[a-zA-Z0-9\-\s\_]*$/, //Only AlphaNumeric with Hyphen and Underscore
	13: /^[a-zA-Z0-9\_]*$/, //Only AlphaNumeric with  Underscore
	14: /^[a-zA-Z0-9\-\s]*$/, //Only AlphaNumeric with Hyphen with space and hyphab
	15: /^[a-zA-Z0-9\-]*$/, //Only AlphaNumeric with Hyphen
}

const ERROR_MESSAGE = {
	2: 'Invalid input only alphanumeric with no special characters allowed',
	3: 'Invalid input only alphanumeric with special chars Allowed',
	4: 'Invalid input only ASCII chars allowed',
	8: 'Invalid input only alphabets allowed',
	9: 'Invalid input only alphanumeric with hyphen and ampersand Allowed',
	10: 'Invalid input only alphanumeric with hyphen and Underscore Allowed',
	13: 'Invalid input only alphanumeric with Underscore Allowed',
	14: 'Invalid input only alphanumeric with space and hyphen Allowed',
	15: 'Invalid input only alphanumeric with hyphen  Allowed',
}

const LongText = ({
	field,
	isDisabled = true,
	isRequired,
	onValidate,
	onError,
}) => {
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

	const doValidate = value => {
		value = value.trim()
		if (
			field?.field_inline_data?.flag_input_setting &&
			field.field_inline_data.flag_input_setting >= 2
		) {
			if (
				!VALIDATION_SCHEMA[field.field_inline_data.flag_input_setting].test(
					value
				)
			) {
				onValidate(false)
				onError(ERROR_MESSAGE[field.field_inline_data.flag_input_setting])
			} else {
				onValidate(true)
			}
		} else if (!value) {
			onError(null)
			onValidate(false)
		} else {
			onError(null)
			onValidate(true)
		}

		if (
			field?.field_inline_data?.text_max_length &&
			field?.field_inline_data?.text_max_length < value.length
		) {
			onValidate(false)
			onError(
				`Max char length should be ${field.field_inline_data.text_max_length} (${value.length}/${field.field_inline_data.text_max_length})`
			)
		}
	}

	useEffect(() => {
		if (!!fields[field.field_id] && fields[field.field_id].field_value) {
			onChange(fields[field.field_id].field_value)
		}
	}, [!!fields[field.field_id]])

	const onChange = value => {
		if (!!isRequired) {
			if (!!value) {
				doValidate(value)
			} else {
				onValidate(false)
			}
		} else {
			doValidate(value)
		}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: loadFinalValue(field, value),
		})
	}
	return (
		<Textarea
			className={`long-text-field-${toSnakeCase(field.field_name)}`}
			autoComplete='off'
			type='text'
			isDisabled={isDisabled}
			placeholder={field.field_place_holder || `Enter ${field.field_name}`}
			borderRadius='md'
			size='sm'
			rows={3}
			resize='vertical'
			value={fields[field.field_id] ? fields[field.field_id].field_value : ''}
			onChange={e => onChange(e.target.value)}
		/>
	)
}

export default LongText
